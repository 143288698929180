<template>
  <div class="ecommerce-application">

    <div class="row mb-2">
      <div v-if="!readOnly" class="col-sm-4">
        <h2 class="content-header-title border-right-0 pr-1 my-1">
          {{ title != '' ? capitalize($t(title)) : capitalize($t($route.name)) }}
        </h2>

        <button-add
            @click="$emit('addItem')"
            v-if="allowAdd"
            :withIcon="true"
        />

        <!--        <b-button-->
        <!--            v-if="allowAdd"-->
        <!--            v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
        <!--            variant="outline-primary"-->
        <!--            @click="$emit('addItem')"-->
        <!--            class="mb-1 mb-md-0"-->
        <!--        >-->
        <!--          {{ $t('Add') }}-->
        <!--        </b-button>-->

        <button-upload
            @click="$emit('addItemByUpload')"
            v-if="allowAddByUpload"
            :withText="false"
            :withIcon="true"
            class="ml-2 px-1 mt-0 btnUpload"
        />
        <!--        <b-button-->
        <!--            v-if="allowAddByUpload"-->
        <!--            v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
        <!--            variant="outline-primary"-->
        <!--            @click="$emit('addItemByUpload')"-->
        <!--            class="mb-1 mb-md-0 ml-2 px-1"-->
        <!--        >-->
        <!--          <icon icon="upload"/>-->
        <!--        </b-button>-->

      </div>

      <div :class="'col-sm-'+(readOnly?'12':'8')">
        <!-- Filters -->
        <div class="ecommerce-header-items">
          <div class="result-toggler text-nowrap">
            <div class="search-results pb-75">

              <slot name="resultInfos"
                    :items="filteredItems">
                {{ filteredItems.length }} {{ $tc('resultFound', filteredItems.length) }}
              </slot>

            </div>
          </div>

          <div class="d-inline-flex w-100">
            <div class="view-options d-flex w-100">

              <b-dropdown
                  v-show="itemView != 'map'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :text="itemPerPagesDisplay"
                  variant="flat-primary"
                  style="height: 37.77px;"
              >
                <b-dropdown-item @click="filters.perPage=50">
                  50
                </b-dropdown-item>
                <b-dropdown-item @click="filters.perPage=100">
                  100
                </b-dropdown-item>
                <b-dropdown-item @click="filters.perPage=150">
                  150
                </b-dropdown-item>
                <b-dropdown-item @click="filters.perPage=200">
                  200
                </b-dropdown-item>
              </b-dropdown>

              <!-- Filter select  -->
              <field-select
                  :model.sync="sortBy"
                  :multiple="true"
                  :displayLabel="false"
                  :name="'filter'"
                  :label="'text'"
                  :options="sortByOptions"
                  :reduce="'key'"
                  :key="componentKey"
                  class="w-100"
              />

              <!-- View Buttons Group  -->
              <b-form-radio-group
                  v-if="!readOnly"
                  v-model="itemView"
                  class="ml-1 list item-view-radio-group"
                  buttons
                  size="sm"
                  button-variant="outline-primary"
                  style="height: 37.77px;"
              >
                <b-form-radio
                    v-for="option in itemViewOptions"
                    :key="option.value"
                    :value="option.value"
                >
                  <icon
                      :icon="option.icon"
                  />
                </b-form-radio>
              </b-form-radio-group>

            </div>
          </div>

        </div>

        <!-- Searchbar -->
        <div class="ecommerce-searchbar">
          <b-row>
            <b-col cols="12">
              <b-input-group class="input-group-merge">
                <b-form-input
                    v-model="filters.query"
                    :placeholder="$t('Search')"
                    class="search-product"
                />
                <b-input-group-append is-text>
                  <icon icon="search"
                        class="text-muted"/>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </div>
      </div>

    </div>

    <slot name="sectionsTop" />


    <!--    Grid view-->
    <section v-if="itemView == 'grid'">
      <b-row>
        <b-col
            cols="12"
            md="3"
            v-for="(item, index) in paginatedItems"
            :key="index"
        >
          <slot
              name="gridView"
              :item="item"
          />
        </b-col>

      </b-row>
    </section>

    <!--    List view-->
    <section v-if="itemView == 'list'">
      <b-row>
        <b-col cols="12">

          <b-card no-body>
            <b-table
                responsive
                :items="paginatedItems"
                :fields="tableColumnsLocal"
                :tbody-tr-class="rowClass"
                :sort-compare="tableSortCompare"
                striped
                hover
                @row-clicked="$emit('columnClick', $event)"
            >

              <!--              Column head-->
              <template
                  v-for="(tableColumn, index) in tableColumns"
                  v-slot:[`head(${tableColumn.key})`]="data">

                <slot
                    :name="'listView_head_'+tableColumn.key"
                    :item="data.label"
                >
                  {{ data.label }}
                </slot>

              </template>

              <!--              Cell-->
              <template
                  v-for="(tableColumn, index) in tableColumns"
                  v-slot:[`cell(${tableColumn.key})`]="data">

                <slot
                    :name="'listView_cell_'+tableColumn.key"
                    :item="data.item"
                >
                  <span v-if="['preTaxAmount', 'vat', 'totalAmount'].includes(tableColumn.key)">
                    {{ currency(data.item[tableColumn.key]) }}
                  </span>

                  <span v-else>{{ data.item[tableColumn.key] }}</span>
                </slot>

              </template>


              <!--              Actions-->
              <template #cell(actions)="data">
                <slot
                    name="listView_cell_actions"
                    :item="data.item"
                >
                </slot>
              </template>

            </b-table>
          </b-card>

        </b-col>
      </b-row>
    </section>


    <!--    Map view-->
    <section v-if="itemView == 'map'">
      <map-view ref="mapView"></map-view>
    </section>

    <!-- Pagination -->
    <section
        v-show="itemView != 'map'"
        class="mt-1">
      <b-row>
        <b-col cols="12">
          <b-pagination
              v-model="filters.page"
              :total-rows="filteredItems.length"
              :per-page="filters.perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <icon icon="chevron-left"/>
            </template>
            <template #next-text>
              <icon icon="chevron-right"/>
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { useSearchFilter, useShopUi } from './useSearchFilter'
import { capitalize, currency } from '@/utils/filter'
import FieldSelect from '@/components/input/Select'
import i18n from '@/libs/i18n'
import MapView from '@/components/map/Map'
import ButtonAdd from '@/components/button/Add'
import ButtonUpload from '@/components/button/Upload'
import moment from 'moment'

export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    FieldSelect,
    MapView,
    ButtonAdd,
    ButtonUpload
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    additionalSortOptions: {
      type: Array,
      default: () => []
    },
    additionalItemViews: {
      type: Array,
      default: () => []
    },
    selectedView: {
      type: String,
      default: 'grid'
    },
    keyTitleForAlphabeticalSort: {
      type: String,
      required: true
    },
    excludedKeys: {
      type: Array,
      default: () => []
    },
    allowAdd: {
      type: Boolean,
      default: true
    },
    allowAddByUpload: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    // For table
    tableColumns: {
      type: Array,
      default: () => []
    },
    isActionColumnEnabled: {
      type: Boolean,
      default: false
    },
    selectedFilters: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const { filters, sortBy, sortByOptionsInit, recursiveFilter, sortCompare, paginateArray } = useSearchFilter()
    const { itemView, itemViewOptionsInit } = useShopUi()

    const sortByOptions = ref([])
    const itemViewOptions = ref(JSON.parse(JSON.stringify(itemViewOptionsInit.value)))

    const mapView = ref(null)

    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const filteredItems = computed(() => {
      let filteredItems = props.items.filter(item => recursiveFilter(item, filters.value.query, props.excludedKeys))

      let alphabeticalSort = filteredItems
      if (sortBy.value.some(sort => ['ASC', 'DESC'].includes(sort)) != false) {
        alphabeticalSort = filteredItems.sort(sortCompare(props.keyTitleForAlphabeticalSort))

        if (sortBy.value.some(sort => sort == 'DESC')) {
          alphabeticalSort.reverse()
        }
      }

      let filteredItemsAfterEAdditionalFilters = alphabeticalSort
      sortBy.value.filter(sort => ['ASC', 'DESC'].includes(sort) == false).forEach(additionalFilter => {
        filteredItemsAfterEAdditionalFilters = filteredItemsAfterEAdditionalFilters.filter(item => {
          return item._filters.some(flt => flt.key == additionalFilter && flt.value == true)
        })
      })

      // console.log("mapView", mapView.value);
      if (mapView.value != null) {
        mapView.value.populateItems(filteredItemsAfterEAdditionalFilters)
      }

      return filteredItemsAfterEAdditionalFilters
    })

    const paginatedItems = computed(() => {
      // console.log(filteredItems.value)
      // filteredItems.value.then(x => console.log(x))

      return paginateArray(filteredItems.value, filters.value.perPage, filters.value.page)
    })

    const itemPerPagesDisplay = computed(() => {
      return (filters.value.page * filters.value.perPage - (filters.value.perPage - 1)).toString() + ' - ' +
          (paginatedItems.value.length < filters.value.perPage ? filteredItems.value.length : (filters.value.page * paginatedItems.value.length)).toString() +
          ' ' + i18n.t('on') + ' ' + filteredItems.value.length.toString()
    })

    const tableColumnsLocal = computed(() => {
      let output = JSON.parse(JSON.stringify(props.tableColumns))

      if (props.isActionColumnEnabled) {
        output.push({ key: 'actions', label: i18n.tc('action', 2) })
      }

      return output
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch([filters, sortBy], () => {
      // console.log('Filters changes')
      // console.log(sortBy.value)
      if (sortBy.value.filter(sort => ['ASC', 'DESC'].includes(sort) != false).length == 2) {
        let ascSort = sortBy.value.findIndex(sort => sort == 'ASC')
        let descSort = sortBy.value.findIndex(sort => sort == 'DESC')

        if (ascSort < descSort) {
          sortBy.value.splice(ascSort, 1)
        } else {
          sortBy.value.splice(descSort, 1)
        }
      }
    }, {
      deep: true
    })
    watch(itemView, () => {
      // console.log('View changes')
      // console.log(itemView.value)
    })

    watch(props.additionalSortOptions, () => {
      updateSortByOptions()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const updateSortByOptions = () => {
      let i18nAdditionalSortOptions = JSON.parse(JSON.stringify(props.additionalSortOptions))
      i18nAdditionalSortOptions.forEach(sortOption => {
        sortOption.text = capitalize(i18n.tc(sortOption.text))
      })

      sortByOptions.value = JSON.parse(JSON.stringify(sortByOptionsInit.value)).concat(i18nAdditionalSortOptions)
    }

    const rowClass = (item) => {
      if (item._isDisabled == true) return 'table-secondary'
    }

    const tableSortCompare = (aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) => {
      const a = aRow[key] // or use Lodash `_.get()`
      const b = bRow[key]
      if (
          (typeof a === 'number' && typeof b === 'number') ||
          (a instanceof Date && b instanceof Date)
      ) {
        // If both compared fields are native numbers or both are native dates
        return a < b ? -1 : a > b ? 1 : 0
      } else if(moment(a).isValid() && moment(b).isValid()) {
        return moment(a) < moment(b) ? -1 : moment(a) > moment(b) ? 1 : 0
      } else {
        // Otherwise stringify the field data and use String.prototype.localeCompare
        return toString(a).localeCompare(toString(b), compareLocale, compareOptions)
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------
    onMounted(() => {
      // sortByOptions.value = sortByOptions.value.concat(props.additionalSortOptions)
      updateSortByOptions()

      itemViewOptions.value = itemViewOptions.value.concat(props.additionalItemViews)
      // props.additionalItemViews.forEach(additionalItemView => {
      //   if (additionalItemView == 'list') {
      //     itemViewOptions.value.push({ icon: 'list', value: 'list' })
      //   }
      // })
    })
    onMounted(() => {
      itemView.value = props.selectedView
    })

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    sortBy.value = props.selectedFilters

    return {
      capitalize,
      currency,

      filters,
      sortBy,
      sortByOptions,
      componentKey,

      // useShopUi
      itemView,
      itemViewOptions,

      filteredItems,
      paginatedItems,
      itemPerPagesDisplay,
      tableColumnsLocal,
      rowClass,
      tableSortCompare,

      mapView
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    selectedFilters: function (val) {
      this.sortBy = val
      this.componentKey++
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/base/pages/ecommerce.scss";
@import '~@/assets/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss"
       scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>