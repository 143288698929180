import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import { comparableString, sortCompare } from '@/utils/utils'

export const useSearchFilter = () => {
  // Filter
  const filters = ref({
    query: '',
    page: 1,
    perPage: 50,
  })

  // Sorting
  const sortBy = ref([])
  const sortByOptionsInit = ref([
    { text: i18n.t('From A to Z'), key: 'ASC' },
    { text: i18n.t('From Z to A'), key: 'DESC' },
    { text: '----------------', key: '-', disabled: true },
  ])

  // Methods
  const recursiveFilter = (item, query, excludedKeys) => {
    return Object.values(item).some((el, index) => {
      if (!['logs', 'uuid'].concat(excludedKeys).includes(Object.keys(item)[index])) {
        if (el != null && typeof el === 'object') {
          return recursiveFilter(el, query, excludedKeys)
        } else if (isLikeQuery(el, query)) {
          return true
        }
      }
    })
  }

  const isLikeQuery = (item, searchQuery) => {
    if (item == null || typeof item == 'boolean') {
      return false
    }

    if (/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:Z|(\+|-)([\d|:]*))?$/.test(item)) {
      item = comparableString(moment(item).format('LL'))
    } else {
      item = comparableString(item.toString())
    }
    let query = comparableString(searchQuery)

    if (item.includes(query)) {
      return true
    } else {
      return false
    }
  }

  const paginateArray = (array, perPage, page) => {
    let workedArray = JSON.parse(JSON.stringify(array))
    return workedArray.slice((page - 1) * perPage, page * perPage)
  }

  const moment = require('moment')

  return {
    // Filter
    filters,

    // Sort
    sortBy,
    sortByOptionsInit,

    // Methods
    recursiveFilter,
    sortCompare,
    paginateArray
  }
}

export const useShopUi = () => {
  const itemView = ref('grid')
  const itemViewOptionsInit = ref([
    { icon: 'th-large', value: 'grid' },
    { icon: 'list', value: 'list' },
    // { icon: 'globe-americas', value: 'map' },
  ])

  return {
    itemView,
    itemViewOptionsInit,
    // totalProducts,
  }
}